export default class MobileMenu {

  constructor() {
    this.initialize();
  }

  private initialize() {
    const burger: HTMLElement = document.getElementById('burger');
    const navigation = document.getElementById('mobile-navigation');

    burger.addEventListener('click', (e) => this.burgerClick(e, burger, navigation))
  }

  private burgerClick(e, burger: HTMLElement, navigation: HTMLElement) {
    e.preventDefault();

    if (burger.classList) {
      if (burger.classList.contains('header__burger_open')) {
        burger.classList.remove('header__burger_open');
        navigation.classList.remove('mobile-navigation_open');
        document.body.classList.remove('b-hidden');
      } else {
        burger.classList.add('header__burger_open');
        navigation.classList.add('mobile-navigation_open');
        document.body.classList.add('b-hidden');
      }
    }
  }

}
