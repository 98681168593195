console.log('init');

import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper/core';

import Header from './components/header';
import MobileMenu from './components/mobile-menu';

new Header();
new MobileMenu();

SwiperCore.use([Navigation, Pagination, EffectFade]);

const swiper = new Swiper('.swiper-container_full', {
  direction: 'horizontal',
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },

  effect: 'fade',
  speed: 1000,

  fadeEffect: {
    crossFade: true
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});

const swiperGifts = new Swiper('.swiper-container_gifts', {
  slidesPerView: 2,
  spaceBetween: 10,
  slidesPerGroup: 2,
  breakpoints: {
    640: {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerGroup: 3,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerGroup: 3,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 30,
      slidesPerGroup: 4,
    },
  },
  loop: true,
  loopFillGroupWithBlank: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  }
});

export default { swiper, swiperGifts};
