export default class Header {

  public headerEl: HTMLElement;

  constructor() {
    document.addEventListener('scroll', this.scroll);
  }

  private scroll(event) {
    if (!this.headerEl) {
      this.headerEl = document.getElementById('header');
    }

    if (window.scrollY > 250 && this.headerEl && !this.headerEl.classList.contains('header_scroll')) {
      this.headerEl.classList.add('header_scroll', 'header_fade-in');
      this.headerEl.classList.remove('header_fade-out');
    } else if (window.scrollY <= 250 && this.headerEl && this.headerEl.classList.contains('header_scroll')) {
      this.headerEl.classList.add('header_fade-out');
      setTimeout(() => {
        this.headerEl.classList.remove('header_scroll', 'header_fade-in', 'header_fade-out')
      }, 50)
    }
  }

}
